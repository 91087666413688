<template>
    <div class="d-flex flex-row flex-wrap">
        <div class="w-100">
            <Header title="" />
        </div>
        <left-side-bar />
        <div class="d-flex flex-row flex-wrap align-items-center w-75 pl-3">
            <div
                class="
                    d-flex
                    flex-row flex-wrap
                    justify-between
                    align-items-center
                    w-100
                "
            >
                <h1 class="font-xx-large font-weight-bold">Transporte</h1>
            </div>
            <el-tabs
                @tab-click="onTabChanged"
                v-model="activeName"
                class="w-100"
            >
                <el-tab-pane label="Rutas" name="first"></el-tab-pane>
            </el-tabs>
            <h1 class="font-x-large font-weight-bold">Rutas</h1>
            <nav class="d-flex flex-row align-items-center text-gray-dark ml-3">
                <router-link
                    to="routes"
                    class="d-flex flex-row align-items-center text-gray-dark"
                >
                    <i
                        class="material-icons text-gray-dark pr-2"
                        style="font-size: 18px"
                        >arrow_back</i
                    >
                    Rutas
                </router-link>
                <i class="p-5">/</i>
                <router-link to="#" class="text-gray-dark white-space-nowrap">
                    Creaci&oacute;n de ruta
                </router-link>
            </nav>
            <div class="d-flex flex-row flex-wrap w-100 pt-3 pl-1">
                <div class="d-flex flex-column pr-3 mb-3">
                    <h3
                        class="
                            text-secondary
                            m-0
                            pl-3
                            white-space-nowrap
                            font-large
                        "
                    >
                        Informaci&oacute;n de la ruta
                    </h3>
                    <div class="d-flex flex-column pl-3 pt-10 font-medium">
                        <div class="d-flex flex-column">
                            <span>Fecha</span>
                            <span
                                class="
                                    text-black
                                    font-medium
                                    mt-2
                                    font-weight-bold
                                "
                            >
                                {{ moment(route.date).format("DD MMM YYYY") }}
                            </span>
                        </div>
                        <div class="d-flex flex-column mt-10">
                            <span>Turno</span>
                            <i class="material-icons text-primary mt-2">{{
                                parseInt(route.shift_day)
                                    ? "light_mode"
                                    : "nightlight"
                            }}</i>
                        </div>
                        <div class="d-flex flex-column mt-10">
                            <span>Deliveries</span>
                            <ul
                                class="
                                    list-delivery
                                    text-black
                                    mt-2
                                    list-style-none
                                    p-0
                                "
                            >
                                <li
                                    v-for="d in route.deliveries"
                                    :key="d.id"
                                    class="mt-2"
                                >
                                    {{ d.name }}
                                </li>
                            </ul>
                        </div>
                        <div class="d-flex flex-column mt-10">
                            <span>Zona</span>
                            <div class="mt-2 font-weight-bold text-black">
                                {{ route.neighbourhood.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex flex-column pl-3 w-75"
                    style="border-left: 1px solid #c0c4cc"
                >
                    <h3
                        class="
                            text-secondary
                            m-0
                            white-space-nowrap
                            font-large
                            w-100
                        "
                    >
                        Ruta
                    </h3>
                    <div class="d-flex flex-column pt-2 font-medium w-100">
                        <div
                            class="
                                bg-primary
                                p-1
                                w-100
                                d-flex
                                flex-row
                                align-items-center
                            "
                            style="border-radius: 4px"
                        >
                            <div
                                class="
                                    d-flex
                                    flex-row
                                    align-items-center
                                    text-white
                                    font-small
                                "
                            >
                                <i
                                    class="material-icons text-white pr-3"
                                    style="font-size: 17px"
                                    >person</i
                                >
                                Delivery
                            </div>
                            <div
                                class="ml-2 text-white material-icons"
                                style="font-size: 17px"
                            >
                                navigate_next
                            </div>
                            <div
                                class="
                                    d-flex
                                    flex-row
                                    align-items-center
                                    text-white
                                    font-small
                                    pl-1
                                "
                            >
                                <i
                                    class="material-icons text-white pr-3"
                                    style="font-size: 17px"
                                    >location_on</i
                                >
                                Zona
                            </div>
                            <div
                                class="ml-2 text-white material-icons"
                                style="font-size: 17px"
                            >
                                navigate_next
                            </div>
                            <div
                                class="
                                    d-flex
                                    flex-row
                                    align-items-center
                                    text-white
                                    font-small
                                    pl-1
                                "
                            >
                                <i
                                    class="material-icons text-white pr-3"
                                    style="font-size: 17px"
                                    >receipt_long</i
                                >
                                &Oacute;rdenes
                            </div>
                        </div>
                        <div class="mt-1">
                            <h4 class="font-weight-bold">
                                Procesa las &oacute;rdenes
                            </h4>
                            <button
                                class="
                                    btn
                                    bg-dark
                                    mt-2
                                    text-white
                                    pl-3
                                    pr-3
                                    text-uppercase
                                    font-small
                                "
                                @click="processOrder()"
                            >
                                Procesar
                            </button>
                        </div>
                        <div
                            class="
                                mt-4
                                d-flex
                                flex-row flex-wrap
                                align-items-flex-start
                                w-100
                            "
                        >
                            <div
                                class="d-flex flex-column"
                                style="
                                    border: 1px solid #dcdfe6;
                                    min-width: 200px;
                                "
                            >
                                <span
                                    class="
                                        pt-5
                                        pb-5
                                        pl-4
                                        text-primary
                                        font-weight-bold
                                    "
                                    style="border-bottom: 1px solid #dcdfe6"
                                    >&Oacute;rdenes</span
                                >
                                <div
                                    class="w-100 overflow-y-scroll"
                                    style="height: 500px"
                                >
                                    <div
                                        v-for="o in this.route.orders"
                                        :key="o.id"
                                        class="
                                            d-flex
                                            flex-row
                                            justify-between
                                            align-items-center
                                            w-100
                                            pt-3
                                            pb-3
                                            m-0
                                        "
                                        style="border-bottom: 1px solid #dcdfe6"
                                    >
                                        <span class="text-black p-2">{{
                                            o.orderNumber
                                        }}</span>
                                        <router-link to="#">
                                            <i
                                                class="
                                                    material-icons
                                                    text-secondary
                                                    p-1
                                                "
                                                >delete</i
                                            >
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="d-flex flex-column flex-grow-2 ml-3"
                                style="
                                    border: 1px solid #dcdfe6;
                                    min-width: 290px;
                                "
                            >
                                <span
                                    class="
                                        pt-2
                                        pb-2
                                        pl-1
                                        text-primary
                                        font-weight-bold
                                    "
                                    style="border-bottom: 1px solid #dcdfe6"
                                    >Deliverys</span
                                >
                                <el-collapse class="pl-3">
                                    <el-collapse-item
                                        v-for="d in route.deliveries"
                                        :key="d.userId"
                                        :title="d.name"
                                    >
                                        <p
                                            v-for="order in d.orders"
                                            :key="order"
                                            class="text-black"
                                        >
                                            {{ order }}
                                        </p>
                                    </el-collapse-item>
                                </el-collapse>
                            </div>
                        </div>
                    </div>
                    <div
                        class="
                            d-flex
                            flex-row flex-wrap
                            justify-between
                            text-align-center
                            mt-5
                        "
                    >
                        <router-link to="RouteCreationStep2" class="">
                            <button
                                class="btn bg-white pl-3 pr-3 text-center"
                                style="width: 100px"
                            >
                                Cancelar
                            </button>
                        </router-link>
                        <el-button
                            class="pull-end bg-yellow"
                            style="float: right"
                            round
                            :disabled="!processed"
                            @click="next()"
                            >Continuar</el-button
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="w-100 bg-primary mt-10" style="height: 20px"></div>
    </div>
</template>

<script>
import leftSideBar from "@/components/dashboard/menu/leftSideBar";
import Header from "@/components/dashboard/include/Header";
import _order_service from "@/services/orderService";
import _routeList_service from "@/services/routeListService";
import moment from "moment";
import { ElMessage, ElButton } from "element-plus";
export default {
    name: "step2",
    components: { Header, leftSideBar, ElButton },
    data() {
        return {
            route: {
                date: null,
                shift_day: null,
                shift_night: null,
                deliveries: [],
                municipality_id: null,
                neighbourhood: null,
                municipalities: [],
                neighbourhoods: [],
                orders: [],
            },
            processed: false,
            activeName: "first",
        };
    },
    created() {
        this.moment = moment;
        this.route.date = localStorage.getItem("route_date");
        this.route.shift_day = localStorage.getItem("route_shift_day");
        this.route.shift_night = localStorage.getItem("route_shift_night");
        this.route.deliveries = JSON.parse(
            localStorage.getItem("route_deliveries")
        ).map((t) => ({
            name: t.firstName + " " + t.lastName,
            orders: [],
            userId: t.id,
        }));
        this.route.neighbourhood = JSON.parse(
            localStorage.getItem("route_neighbourhood")
        );

        let data_order = {
            PageSize: 1000,
            PageIndex: 1,
            NeighborhoodId: this.route.neighbourhood.id,
        };

        _order_service.getOrderByNeighborhood(data_order).then((order) => {
            this.route.orders = order.data.items;
        });
    },
    methods: {
        onTabChanged(name) {
            this.tabRole = name.props.label;
        },
        next() {
            this.$router.push("Routes");
        },
        async processOrder() {
            var payload = {
                orders: this.route.orders.map((t) => t.id),
                deliveries: this.route.deliveries.map((t) => t.userId),
                workSessionId: parseInt(this.route.shift_day) ? 1 : 2,
                date: new Date(this.route.date),
            };

            _routeList_service
                .processRoute(payload)
                .then((data) => {
                    ElMessage({
                        message: "La orden se ha procesado de manera exitosa.",
                        type: "success",
                    });
                    this.processed = true;
                    if (data != undefined) {
                        this.route.deliveries = data.data;
                    }
                })
                .catch(() => {
                    ElMessage({
                        message:
                            "Ha ocurrido un error inesperado al procesar la orden.",
                        type: "danger",
                    });
                });
        },
    },
};
</script>

<style scoped>
.list-delivery li,
.list-zone li,
.el-collapse-item__header {
    font-weight: bold !important;
}
</style>
